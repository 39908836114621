import {
	CheckOutlined,
	CloudSyncOutlined,
	LoadingOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	DatePicker,
	InputNumber,
	Radio,
	Row,
	Select,
	Table,
	Tooltip,
} from 'antd';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import { getApi, getApiExcel, postApi, putApi } from 'apis';
import { RoleContext } from 'components/Global/AuthLayout';
import Ellipse from 'components/Global/Ellipse';
import Export from 'components/Global/Export';
import Sync from 'components/Global/Sync';
import { months } from 'constants/Data';
import dayjs from 'dayjs';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';

const SalesOrdersTable: any = () => {
	const { Column } = Table;
	const navigate = useNavigate();
	const roleContext = useContext(RoleContext);
	const [isExportLoading, setIsExportLoading] = useState(false);
	const allSalesRep = useSelector((state: any) => state.salesReps.data);
	const user = useSelector((state: any) => state.userProfile.data);

	const date = new Date();
	const [salesOrder, setSalesOrder] = useState([]);
	const [selectedRadio, setSelectedRadio] = useState<any>({});
	const [year, setYear] = useState(dayjs().year());
	const [month, setMonth] = useState(date.getMonth() + 1);
	const [commissionType, setCommissionType] = useState('');
	const [salesRepId, setSalesRepId] = useState(allSalesRep[0]?.id);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [isSync, setIsSync] = useState(false);

	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [updatedSalesOrders, setUpdatedSalesOrders] = useState<any[]>([]);
	const [salesRepcommissionTypes, setSalesRepCommissionTypes] = useState<
		any[]
	>([]);

	// const [assignedSalesRepId, setAssignedSalesRepId] = useState<{
	// 	[key: string]: string;
	// }>({});

	const getSalesOrder = async () => {
		try {
			setIsLoading(true);
			const response = await getApi('/invoices/sales-order', {
				salesRepId: salesRepId,
				month: month,
				year: year,
				page: currentPage,
				limit: pageSize,
				commissionType: commissionType,
			});
			setSalesOrder(response.data.data);
			setTotalRecords(response.data.total);
		} catch (error: any) {
			console.log('error: ', error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleRadioChange = async (record: any, _commissionType: string) => {
		try {
			const _updatedSalesOrders = updatedSalesOrders;
			const _salesRepId = salesRepId;
			console.log('_salesRepId: ', _salesRepId);

			if (_updatedSalesOrders.length == 0) {
				_updatedSalesOrders.push({
					orderId: record.id,
					salesRepId: _salesRepId,
					commissionType: _commissionType,
				});
			} else {
				const isExist = _updatedSalesOrders.findIndex(
					(item) => item.orderId === record.id
				);
				if (isExist >= 0) {
					_updatedSalesOrders[isExist] = {
						orderId: record.id,
						salesRepId: _salesRepId,
						commissionType: _commissionType,
					};
				} else {
					_updatedSalesOrders.push({
						orderId: record.id,
						salesRepId: _salesRepId,
						commissionType: _commissionType,
					});
				}
			}

			setUpdatedSalesOrders(_updatedSalesOrders);

			setIsLoading(true);
			setSelectedRadio({
				...selectedRadio,
				[record.id]: {
					...selectedRadio[record.id],
					commissionType: _commissionType,
				},
			});
			const response = await putApi('/invoices/', {
				salesRepId: _salesRepId,
				orderId: record.id,
				selectedCommission: commissionType,
				commissionType: _commissionType,
			});

			const index = salesOrder.findIndex(
				(item: any) => item.id === record.id
			);

			if (index !== -1) {
				const updatedSalesOrder: any = [...salesOrder];
				updatedSalesOrder[index] = response.data.data;
				setSalesOrder(updatedSalesOrder);
			}
		} catch (error: any) {
			console.error('Error updating commission type:', error);
		} finally {
			setIsLoading(false);
		}
	};

	// const handleSalesRepChange = (recordId: string, salesRepId: string) => {
	// 	setAssignedSalesRepId((prevState) => ({
	// 		...prevState,
	// 		[recordId]: salesRepId,
	// 	}));
	// };

	const handleAssignSalesRep = async (recordId: string) => {
		try {
			setIsLoading(true);
			const filteredData = updatedSalesOrders.find(
				(order) => order.orderId === recordId
			);
			if (filteredData) {
				const response = await postApi(
					'/reports/no-commission-report',
					filteredData
				);
				getSalesOrder();
				toastText(response.data.message, 'success');
			} else {
				toastText('Please select commission type', 'error');
			}
		} catch (error) {
			console.log('error: ', error);
		} finally {
			setIsLoading(false);
		}
	};

	const expandedRowRender = (record: any) => {
		const lineItems = record.invoiceItems.map((item: any) => ({
			id: item.id,
			product: item.product,
			description: item.description,
			qty: item.qty,
			rate: item.rate,
			amount: item.amount,
		}));

		return (
			<Table
				dataSource={lineItems}
				pagination={false}
				style={{ width: '80rem', padding: '1.5rem 0px' }}
			>
				<Column
					title="#"
					dataIndex="id"
					key="id"
					className="bg-white"
					render={(value, data, index) => `${index + 1}`}
				/>
				<Column
					title="Product"
					dataIndex="product"
					key="product"
					className="bg-white"
					render={(text) => {
						return text ? <p>{text}</p> : '-';
					}}
				/>
				<Column
					title="Description"
					dataIndex="description"
					key="description"
					className="bg-white"
					render={(value) => {
						return value ? (
							<Ellipse
								message={String(value)}
								maxLength={50}
								isTooltip={true}
								tooltipMessage={value}
							/>
						) : (
							'-'
						);
					}}
				/>
				<Column
					title="Qty"
					dataIndex="qty"
					key="qty"
					className="bg-white"
					render={(text) => {
						return text ? <p>{text}</p> : '-';
					}}
				/>
				<Column
					title="Rate"
					dataIndex="rate"
					key="rate"
					className="bg-white"
					render={(text) => {
						return text ? <p>${text}</p> : '-';
					}}
				/>
				<Column
					title="Amount"
					dataIndex="amount"
					key="amount"
					className="bg-white"
					render={(text) => {
						return text ? <p>${text}</p> : '-';
					}}
				/>
			</Table>
		);
	};

	const getMonth = (month: any) => {
		setMonth(month);
	};

	const handleCommissionType = (type: string) => {
		setCommissionType(type);
		setCurrentPage(1);
	};

	const getSalesRepId = (salesRepId: any) => {
		setSalesRepId(salesRepId);
		const data = allSalesRep.find((item: any) => item.id === salesRepId);
		if (data) {
			setSalesRepCommissionTypes(data.commission);
		} else {
			setSalesRepCommissionTypes([]);
		}
	};

	const handleDeduction = async (
		deduction: any,
		orderId: string,
		commissionId: string
	) => {
		try {
			setIsLoading(true);
			if (deduction.length > 6) {
				return;
			}

			const response = await putApi('/commissions/deduction', {
				orderId: orderId,
				salesRepId: salesRepId,
				deduction: Number(deduction),
				commissionId: commissionId,
				commissionType: commissionType,
			});
			const index = salesOrder.findIndex(
				(item: any) => item.id === orderId
			);

			if (index !== -1) {
				const updatedSalesOrder: any = [...salesOrder];
				updatedSalesOrder[index] = response.data.data;
				setSalesOrder(updatedSalesOrder);
			}
		} catch (error) {
			console.log('error: ', error);
		} finally {
			setIsLoading(false);
		}
	};

	const exportPdf = async () => {
		try {
			setIsExportLoading(true);
			const response: any = await getApiExcel(
				'/reports/sales-order-report/pdf',
				{
					salesRepId: salesRepId,
					month: month,
					year: year,
					commissionType: commissionType,
				}
			);

			const fileName =
				moment(new Date()).format('MM-DD-YYYY_hh-mm-ss') +
				`_${user.firstName} ${user.lastName}-sales-order-report.pdf`;

			const pdfBlob = new Blob([response.data], {
				type: 'application/pdf',
			});
			const pdfUrl = URL.createObjectURL(pdfBlob);
			const link = document.createElement('a');
			link.href = pdfUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (err: any) {
			if (err.response.status === 400) {
				toastText(
					'No report has been generated for this time period',
					'error'
				);
			} else {
				toastText(
					'Something went wrong in exporting pdf report.',
					'error'
				);
			}
		} finally {
			setIsExportLoading(false);
		}
	};

	const exportExcel = async () => {
		try {
			setIsExportLoading(true);
			const response: any = await getApiExcel(
				'/reports/sales-order-report/excel',
				{
					salesRepId: salesRepId,
					month: month,
					year: year,
					commissionType: commissionType,
				}
			);

			const fileName =
				moment(new Date()).format('MM-DD-YYYY_hh-mm-ss') +
				`_${user.firstName} ${user.lastName}-sales-order-report.xlsx`;

			const excelBlob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const excelUrl = URL.createObjectURL(excelBlob);
			const link = document.createElement('a');
			link.href = excelUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (err: any) {
			if (err.response.status === 400) {
				toastText(
					'No report has been generated for this time period',
					'error'
				);
			} else {
				toastText(
					'Something went wrong in exporting excel report.',
					'error'
				);
			}
		} finally {
			setIsExportLoading(false);
		}
	};

	const handleRefresh = async () => {
		try {
			setIsRefreshing(true);
			setIsLoading(true);
			// const response = await getApi('/invoices/refresh', {
			// 	month: month,
			// 	year: year,
			// });
			// toastText(response.data.message, 'success');
			getSalesOrder();
			toastText('Sales orders refreshed successfully.', 'success');
		} catch (error: any) {
			console.log('error: ', error);
			toastText('Something went wrong while refreshing', 'error');
		} finally {
			setIsRefreshing(false);
			setIsLoading(false);
		}
	};

	const handleSyncNow = async () => {
		try {
			setIsSync(true);
			setIsLoading(true);
			const response = await getApi('/config/sync-now');
			getSalesOrder();
			toastText(response.data.message, 'success');
		} catch (error: any) {
			console.log('error: ', error);
			toastText('Something went wrong while refreshing', 'error');
		} finally {
			setIsSync(false);
			setIsLoading(false);
		}
	};

	const handleGenerateReport = async () => {
		try {
			setIsLoading(true);
			const response = await postApi('/reports/sales-order-report', {
				data: updatedSalesOrders,
				month: month,
				year: year,
				salesRepId: salesRepId,
			});
			navigate(
				`/reports?salesRepId=${salesRepId}&month=${month}&year=${year}`
			);
			toastText(response.data.message, 'success');
		} catch (error) {
			toastText('Something went wrong', 'error');
		} finally {
			setIsLoading(false);
		}
	};
	const paginationChangeHandler = (value: number) => {
		setCurrentPage(value);
	};

	const pageSizeHandler = (currentPage: number, pageSize: any) => {
		// setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	useEffect(() => {
		if (allSalesRep && allSalesRep.length > 0) {
			setSalesRepId(allSalesRep[0].id);
		}
	}, [allSalesRep]);

	useEffect(() => {
		if (allSalesRep && allSalesRep.length > 0) {
			getSalesOrder();
			getSalesRepId(salesRepId);
		}
	}, [year, month, salesRepId, commissionType, currentPage, pageSize]);

	useEffect(() => {
		const initialSelectedRadio: any = {};
		salesOrder.forEach((record: any) => {
			if (record.isApplied) {
				initialSelectedRadio[record.id] = {
					commissionType: record.commissionType,
				};
			}
		});
		setSelectedRadio(initialSelectedRadio);
	}, [salesOrder]);

	return (
		<div>
			<div>
				{/* <Row justify="end">
					<Col xs={24} sm={24} md={24} lg={24}>
						<Sync
							text={'Refresh'}
							handleRefresh={handleRefresh}
							isRefreshing={isRefreshing}
						/>
					</Col>
				</Row> */}
				<Row
					justify="end"
					gutter={16}
					style={{ marginBottom: '1.5rem' }}
				>
					<Col>
						<Sync
							text={'Sync Now'}
							handleRefresh={handleSyncNow}
							isRefreshing={isSync}
							tooltip="Sync now if you have made updates in QBO or HubSpot. It will take some time for calculations, so please refresh after a while."
							icon={
								<CloudSyncOutlined
									style={{ fontSize: '14.5px' }}
								/>
							}
						/>
					</Col>
					<Col>
						<Sync
							text={'Refresh'}
							handleRefresh={handleRefresh}
							isRefreshing={isRefreshing}
							tooltip="If the updates to the sales rep commission or global constants haven't been reflected, kindly refresh now. Following the sync, please ensure to perform another refresh to ensure the changes take effect."
							icon={<SyncOutlined />}
						/>
					</Col>
				</Row>
				<Row
					justify="space-between"
					align="middle"
					className={styles['sales-order']}
					gutter={16}
					style={{ marginBottom: '1rem' }}
				>
					<Col>
						<div className={styles['sales-order__date']}>
							<DatePicker
								picker="year"
								format="YYYY"
								style={{ width: 120 }}
								disabledDate={(current) => {
									return (
										current &&
										(current > moment().endOf('year') ||
											current <
												moment()
													.subtract(7, 'years')
													.startOf('year'))
									);
								}}
								onChange={(selectedYear) => {
									setYear(selectedYear.year());
									setCurrentPage(1);
								}}
								value={dayjs().year(year)}
								allowClear={false}
							/>

							<Select
								showSearch
								placeholder="Select month"
								options={months.map((month) => ({
									value: month.value,
									label: month.label,
								}))}
								style={{ width: 120 }}
								onChange={(month) => {
									getMonth(month);
									setCurrentPage(1);
								}}
								value={month}
								optionFilterProp="label"
							/>
							{roleContext.role === 'ADMIN' && (
								<div>
									<span>Sales Rep : </span>

									<Select
										showSearch
										placeholder="Select Sales Rep"
										style={{ width: 180 }}
										value={salesRepId}
										options={allSalesRep.map(
											(allSalesRep: any) => ({
												value: allSalesRep.id,
												label: `${allSalesRep.firstName} ${allSalesRep.lastName}`,
											})
										)}
										onChange={(salesRepId) => {
											getSalesRepId(salesRepId);
											setCurrentPage(1);
										}}
										optionFilterProp="label"
									/>
								</div>
							)}
							<div>
								<span>Commission : </span>
								<Select
									showSearch
									defaultValue=""
									style={{ width: 200 }}
									options={[
										{
											value: '',
											label: 'All Commissions',
										},
										{
											value: 'INSIDE',
											label: 'Inside Commission',
										},
										{
											value: 'OUTSIDE',
											label: 'Outside Commission',
										},
										{
											value: 'SPECIAL_PRODUCT',
											label: 'Special/Intermediate Commission',
										},
										{
											value: 'RETENTION',
											label: 'Retention Commission',
										},
										{
											value: 'NEW_PRODUCT',
											label: 'New Product Commission',
										},
										{
											value: 'NO_COMMISSION',
											label: 'No Commission',
										},
									]}
									onChange={(type) => {
										handleCommissionType(type);
									}}
									optionFilterProp="label"
								/>
							</div>
						</div>
					</Col>
					{commissionType != 'NO_COMMISSION' && (
						<Col>
							<div
								style={{
									display: 'flex',
									gap: '2rem',
									alignItems: 'center',
									justifyContent: 'flex-end',
								}}
							>
								<Tooltip title="Generate Commission Report">
									<Button
										size="middle"
										className="primary-button"
										onClick={handleGenerateReport}
									>
										Generate Comm. Report
									</Button>
								</Tooltip>

								<Export
									handleExport={(type: string) => {
										if (type === 'pdf') {
											exportPdf();
										}
										if (type === 'xls') {
											exportExcel();
										}
									}}
									isLoading={isExportLoading}
								/>
							</div>
						</Col>
					)}
				</Row>
			</div>
			<Table
				dataSource={salesOrder}
				className="custom-ant-table"
				pagination={{
					total: totalRecords,
					current: currentPage,
					onChange: paginationChangeHandler,
					pageSize: pageSize,
					showSizeChanger: true,
					onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
					showTotal: (total) => {
						const totalPages = Math.ceil(total / pageSize);
						const startEntry = (currentPage - 1) * pageSize + 1;
						const endEntry = Math.min(
							currentPage * pageSize,
							total
						);
						const isSingleEntryOnLastPage =
							currentPage === totalPages &&
							startEntry === endEntry;

						// let value = 0;
						// if (currentPage < totalPages) {
						// 	value = currentPage * pageSize; // Full page
						// } else if (currentPage === totalPages) {
						// 	value = total; // Last page or single page case
						// } else {
						// 	value = total; // Page number exceeds total pages
						// }

						if (isSingleEntryOnLastPage) {
							return `Showing ${endEntry} of ${total} entries`;
						} else {
							return `Showing ${startEntry} to ${endEntry} of ${total} entries`;
						}
						// return (
						// 	<p>
						// 		Showing {(currentPage - 1) * pageSize + 1} to{' '}
						// 		{value} of {total} entries
						// 	</p>
						// );
					},
				}}
				expandable={{
					expandedRowRender,
				}}
				scroll={{ y: 'calc(100vh - 400px)' }}
				rowKey="id"
				loading={isLoading}
			>
				<Column
					title="No."
					dataIndex="sr"
					key="sr"
					className="bg-white"
					render={(value, data: any, index) => {
						return <p>{`${index + 1}`}</p>;
					}}
					width={80}
				/>
				<Column
					title="Date"
					dataIndex="date"
					key="date"
					className="bg-white"
					width={120}
				/>

				<Column
					title="Customer"
					dataIndex="customer"
					key="customer"
					className="bg-white"
					width={150}
				/>
				<Column
					title="QBO Invoice"
					dataIndex="qboInvoice"
					key="qboInvoice"
					className="bg-white"
					width={150}
					render={(text, data: any) => (
						<a
							className="text-color"
							href={`${
								process.env.REACT_APP_INVOICE_ENDPOINT
							}/app/invoice?txnId=${text?.replace(/\D/g, '')}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{data.qboInvoiceDoc}
						</a>
					)}
				/>
				<Column
					title="EES Order"
					dataIndex="eesOrder"
					key="eesOrder"
					className="bg-white"
					width={150}
					render={(text) => (
						<a className="text-color"> {text ? text : '-'} </a>
					)}
				/>
				<Column
					title="Amount"
					dataIndex="amount"
					key="amount"
					className="bg-white"
					width={150}
					render={(text) => <span>${text}</span>}
				/>
				<Column
					title="Adjusted Profit"
					dataIndex="adjProfit"
					key="adjProfit"
					className="bg-white"
					width={150}
					render={(text) => <span>${text}</span>}
				/>
				<ColumnGroup title="Type of commission applied">
					<Column
						title="Inside"
						dataIndex="insideCommissionRadio"
						key="insideCommissionRadio"
						className="bg-white"
						width={100}
						render={(text, record: any) => {
							return (
								<Radio
									value="INSIDE"
									checked={
										selectedRadio[record.id]
											?.commissionType === 'INSIDE'
									}
									onChange={() =>
										handleRadioChange(record, 'INSIDE')
									}
									disabled={
										// salesRepcommissionTypes &&
										// salesRepcommissionTypes.length > 0 &&

										!salesRepcommissionTypes ||
										!salesRepcommissionTypes.includes(
											'Inside Commission'
										) ||
										(selectedRadio[record.id]
											?.commissionType !== 'INSIDE' &&
											record.appliedCommissions.includes(
												'INSIDE'
											))
									}
								/>
							);
						}}
					/>
					<Column
						title="Outside"
						dataIndex="outsideCommissionRadio"
						key="outsideCommissionRadio"
						className="bg-white"
						width={100}
						render={(text, record: any) => (
							<Radio
								value="OUTSIDE"
								checked={
									selectedRadio[record.id]?.commissionType ===
									'OUTSIDE'
								}
								onChange={() =>
									handleRadioChange(record, 'OUTSIDE')
								}
								disabled={
									// salesRepcommissionTypes &&
									// salesRepcommissionTypes.length > 0 &&
									!salesRepcommissionTypes ||
									!salesRepcommissionTypes.includes(
										'Outside Commission'
									) ||
									(selectedRadio[record.id]
										?.commissionType !== 'OUTSIDE' &&
										record.appliedCommissions.includes(
											'OUTSIDE'
										))
								}
							/>
						)}
					/>
					<Column
						title="Intermediate"
						dataIndex="specialCommissionRadio"
						key="specialCommissionRadio"
						className="bg-white"
						width={130}
						render={(text, record: any) => (
							<Radio
								value="SPECIAL_PRODUCT"
								checked={
									selectedRadio[record.id]?.commissionType ===
									'SPECIAL_PRODUCT'
								}
								onChange={() =>
									handleRadioChange(record, 'SPECIAL_PRODUCT')
								}
								disabled={
									// salesRepcommissionTypes &&
									// salesRepcommissionTypes.length > 0 &&
									!record.isSpecialProduct ||
									!salesRepcommissionTypes ||
									!salesRepcommissionTypes.includes(
										'Special/Intermediate Commission'
									) ||
									(selectedRadio[record.id]
										?.commissionType !==
										'SPECIAL_PRODUCT' &&
										record.appliedCommissions.includes(
											'SPECIAL_PRODUCT'
										))
								}
							/>
						)}
					/>
					<Column
						title="NPI"
						dataIndex="newProductCommissionRadio"
						key="newProductCommissionRadio"
						className="bg-white"
						width={100}
						render={(text, record: any) => (
							<Radio
								value="NEW_PRODUCT"
								checked={
									selectedRadio[record.id]?.commissionType ===
									'NEW_PRODUCT'
								}
								onChange={() =>
									handleRadioChange(record, 'NEW_PRODUCT')
								}
								disabled={
									!record.isNewProduct ||
									(selectedRadio[record.id]
										?.commissionType !== 'NEW_PRODUCT' &&
										record.appliedCommissions.includes(
											'NEW_PRODUCT'
										))
								}
							/>
						)}
					/>
					<Column
						title="Customer Retention"
						dataIndex="retentionCommmissionRadio"
						key="retentionCommmissionRadio"
						className="bg-white"
						width={180}
						render={(text, record: any) => (
							<Radio
								value="RETENTION"
								checked={
									selectedRadio[record.id]?.commissionType ===
									'RETENTION'
								}
								onChange={() =>
									handleRadioChange(record, 'RETENTION')
								}
								disabled={
									// salesRepcommissionTypes &&
									// salesRepcommissionTypes.length > 0 &&
									!salesRepcommissionTypes ||
									!salesRepcommissionTypes.includes(
										'Customer Retention Commission'
									) ||
									(selectedRadio[record.id]
										?.commissionType !== 'RETENTION' &&
										record.appliedCommissions.includes(
											'RETENTION'
										))
								}
							/>
						)}
					/>
				</ColumnGroup>
				<Column
					title="Commission %"
					dataIndex="commission"
					key="commission"
					className="bg-white"
					width={150}
					render={(text) => <p>{text ? `${text}%` : '0.00%'}</p>}
				/>
				<Column
					title="Commission Amount"
					dataIndex="commissionAmount"
					key="commissionAmount"
					className="bg-white"
					width={150}
					render={(text) => <p>{text ? `$${text}` : '$0.00'}</p>}
				/>
				<Column
					title="Deductions (if any)"
					dataIndex="deductions"
					key="deductions"
					className="bg-white"
					width={150}
					render={(text, record: any) => {
						return (
							<InputNumber
								min={0}
								max={999999}
								type="number"
								value={text}
								parser={(value) => {
									const parsedValue = parseFloat(
										value || '0'
									);
									return isNaN(parsedValue) ? 0 : parsedValue;
								}}
								onBlur={(e) => {
									const value = e.target.value;
									handleDeduction(
										value,
										record.orderId,
										record.commissionId
									);
								}}
								prefix={'$'}
								disabled={
									record?.commissionAmount === '0' ||
									record.commissionAmount === '0.00' ||
									commissionType === 'NO_COMMISSION'
								}
							/>
						);
					}}
				/>

				{/* <Column
					title="Fees"
					dataIndex="fees"
					key="fees"
					className="bg-white"
					width={150}
					render={(text) => <p>{text ? `$${text}` : '$0.00'}</p>}
				/> */}
				<Column
					title="Payouts Amount"
					dataIndex="payoutsAmount"
					key="payoutsAmount"
					className="bg-white"
					width={150}
					render={(text) => <p>{text ? `$${text}` : '$0.00'}</p>}
				/>

				{commissionType === 'NO_COMMISSION' && (
					<Column
						title="Action"
						dataIndex="action"
						key="action"
						className="bg-white"
						width={320}
						render={(text, record: any) => {
							const isExists = updatedSalesOrders.find(
								(order) => order.orderId === record.orderId
							);
							return (
								<div className={styles['no-commission']}>
									{/* <Select
									showSearch
									placeholder="Select Sales Rep"
									style={{ width: 180 }}
									value={assignedSalesRepId[record.id]}
									options={allSalesRep.map(
										(salesRep: any) => ({
											value: salesRep.id,
											label: `${salesRep.firstName} ${salesRep.lastName}`,
										})
									)}
									onChange={(salesRepId) =>
										handleSalesRepChange(
											record.id,
											salesRepId
										)
									}
									optionFilterProp="label"
								/> */}
									<Button
										className={
											commissionType !==
												'NO_COMMISSION' || !isExists
												? styles['assign-button']
												: 'primary-button'
										}
										onClick={() => {
											handleAssignSalesRep(record.id);
										}}
										icon={
											isLoading ? (
												<LoadingOutlined />
											) : (
												<CheckOutlined />
											)
										}
										disabled={
											commissionType !==
												'NO_COMMISSION' || !isExists
										}
									>
										Assign
									</Button>
								</div>
							);
						}}
					/>
				)}
			</Table>
		</div>
	);
};

export default SalesOrdersTable;
