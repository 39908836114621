import { Tabs } from 'antd';
import { NewProductReport } from 'components/NewProductReport';
import { SalesReport } from 'components/SalesReport';
import { useState } from 'react';

const Reports = () => {
	const [isAdmin, setIsAdmin] = useState('detailedCommissionsReports');

	const items = [
		{
			key: 'detailedCommissionsReports',
			label: 'Detailed Commissions Report',
			children: <SalesReport />,
		},
		{
			key: 'newProductMonthlyReports',
			label: 'New Product Commissions Report',
			children: <NewProductReport />,
		},
	];
	return (
		<div>
			<Tabs
				defaultActiveKey="detailedCommissionsReports"
				items={items}
				activeKey={isAdmin}
				onChange={(key) => {
					setIsAdmin(key);
				}}
				style={{ fontSize: '18px' }}
			/>
		</div>
	);
};

export default Reports;
