import { Col, DatePicker, Row, Select, Table } from 'antd';
// import './index.scss';
import { getApi, getApiExcel } from 'apis';
import { RoleContext } from 'components/Global/AuthLayout';
import dayjs from 'dayjs';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { invalidText, toastText } from 'utils/utils';
import Export from 'components/Global/Export';
import styles from './index.module.scss';
import { months } from 'constants/Data';
import { useSelector } from 'react-redux';

interface Props {
	allSalesRep: any;
	newProducts: any;
}

const NewProductReportTable = (props: Props) => {
	const { allSalesRep, newProducts } = props;
	const date = new Date();

	const user = useSelector((state: any) => state.userProfile.data);

	const roleContext = useContext(RoleContext);
	const [isExportLoading, setIsExportLoading] = useState(false);
	const [year, setYear] = useState(dayjs().year());
	const [month, setMonth] = useState(date.getMonth() + 1);
	const [salesRepId, setSalesRepId] = useState(allSalesRep[0]?.id);
	const [isLoading, setIsLoading] = useState(false);
	const [reportData, setReportData] = useState([]);
	const [customer, setCustomer] = useState<any>([
		{
			value: '',
			label: 'All Customers',
		},
	]);
	const [customerId, setCustomerId] = useState(customer[0].value);
	const [productId, setProductId] = useState(newProducts[0].value);

	const getMonth = (month: any) => {
		setMonth(month);
	};

	const exportPdf = async () => {
		try {
			setIsExportLoading(true);
			const response: any = await getApiExcel(
				'/reports/new-product/pdf',
				{
					salesRepId: salesRepId,
					month: month,
					year: year,
					productId: productId,
					customerId: customerId,
				}
			);

			const fileName =
				moment(new Date()).format('MM-DD-YYYY_hh-mm-ss') +
				`_${user.firstName} ${user.lastName}-new-product-commission-report.pdf`;

			const pdfBlob = new Blob([response.data], {
				type: 'application/pdf',
			});
			const pdfUrl = URL.createObjectURL(pdfBlob);
			const link = document.createElement('a');
			link.href = pdfUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (err: any) {
			if (err.response.status === 400) {
				toastText(
					'No report has been generated for this time period',
					'error'
				);
			} else {
				toastText(
					'Something went wrong in exporting pdf report.',
					'error'
				);
			}
		} finally {
			setIsExportLoading(false);
		}
	};
	const exportExcel = async () => {
		try {
			setIsExportLoading(true);
			const response: any = await getApiExcel(
				'/reports/new-product/excel',
				{
					salesRepId: salesRepId,
					month: month,
					year: year,
					productId: productId,
					customerId: customerId,
				}
			);

			const fileName =
				moment(new Date()).format('MM-DD-YYYY_hh-mm-ss') +
				`_${user.firstName} ${user.lastName}-new-product-commission-report.xlsx`;

			const excelBlob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const excelUrl = URL.createObjectURL(excelBlob);
			const link = document.createElement('a');
			link.href = excelUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (err: any) {
			if (err.response.status === 400) {
				toastText(
					'No report has been generated for this time period',
					'error'
				);
			} else {
				toastText(
					'Something went wrong in exporting excel report.',
					'error'
				);
			}
		} finally {
			setIsExportLoading(false);
		}
	};

	const getSalesRepId = (salesRepId: any) => {
		setSalesRepId(salesRepId);
	};

	const handleCustomer = (customerId: any) => {
		setCustomerId(customerId);
	};

	const handleProduct = (productId: any) => {
		setProductId(productId);
	};

	const { Column } = Table;

	const getNewProductReport = async () => {
		try {
			setIsLoading(true);
			const response = await getApi('/reports/new-product', {
				salesRepId: salesRepId,
				month: month,
				year: year,
				productId: productId,
				customerId: customerId,
			});

			setReportData(response.data.data.data);
			if (
				response.data.data.customer &&
				response.data.data.customer.length > 0
			) {
				const data = response.data.data.customer.map(
					(customer: any) => {
						return {
							value: customer.id,
							label: `${customer.firstName} ${customer.lastName}`,
						};
					}
				);

				setCustomer([
					{
						value: '',
						label: 'All Customers',
					},
					...data,
				]);
			}
		} catch (err) {
			toastText('Something went wrong in fetching the report.', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (salesRepId) {
			getNewProductReport();
		}
	}, [year, month, salesRepId, productId, customerId]);

	return (
		<div>
			<div>
				<Row
					justify="space-between"
					align="middle"
					className={styles['sales-report']}
					gutter={16}
					style={{ marginBottom: '1rem' }}
				>
					<Col>
						<div className={styles['sales-report__date']}>
							<DatePicker
								picker="year"
								format="YYYY"
								disabledDate={(current) => {
									return (
										current &&
										(current > moment().endOf('year') ||
											current <
												moment()
													.subtract(4, 'years')
													.startOf('year'))
									);
								}}
								style={{ width: 120 }}
								onChange={(selectedYear) => {
									setYear(selectedYear.year());
								}}
								value={dayjs().year(year)}
								allowClear={false}
							/>
							<Select
								showSearch
								placeholder="Select month"
								options={months.map((month) => ({
									value: month.value,
									label: month.label,
								}))}
								style={{ width: 120 }}
								onChange={(month) => {
									getMonth(month);
								}}
								value={month}
								optionFilterProp="label"
							/>
							{roleContext.role === 'ADMIN' && (
								<div>
									<span>Sales Rep : </span>
									<Select
										showSearch
										placeholder="Select Sales Rep"
										style={{ width: 180 }}
										value={salesRepId}
										options={allSalesRep.map(
											(allSalesRep: any) => ({
												value: allSalesRep.id,
												label: `${allSalesRep.firstName} ${allSalesRep.lastName}`,
											})
										)}
										onChange={(salesRepId) => {
											getSalesRepId(salesRepId);
										}}
										optionFilterProp="label"
									/>
								</div>
							)}
							<div>
								<span>Customer : </span>
								<Select
									showSearch
									style={{ width: 180 }}
									value={customerId}
									onChange={(customerId) => {
										handleCustomer(customerId);
									}}
									optionFilterProp="label"
									options={customer}
								/>
							</div>
							<div>
								<span>Product : </span>
								<Select
									showSearch
									style={{ width: 180 }}
									value={productId}
									onChange={(productId) => {
										handleProduct(productId);
									}}
									optionFilterProp="label"
									options={newProducts}
								/>
							</div>
						</div>
					</Col>

					<Col>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
							}}
						>
							<Export
								handleExport={(type: string) => {
									if (type === 'pdf') {
										exportPdf();
									}
									if (type === 'xls') {
										exportExcel();
									}
								}}
								isLoading={isExportLoading}
							/>
						</div>
					</Col>
				</Row>
			</div>
			<Table
				dataSource={reportData}
				pagination={false}
				scroll={{ x: 500, y: 'calc(100vh - 340px)' }}
				rowClassName={(record: any) => {
					if (record.isTotal) {
						return 'total';
					}
					return '';
				}}
				loading={isLoading}
			>
				<Column
					title="No."
					dataIndex="sr"
					key="sr"
					className="bg-white"
					render={(value, data: any, index) => {
						return data.isSubTotal ||
							data.isTotal ||
							data.isOutsideAdjust ? (
							''
						) : (
							<p>{`${index + 1}`}</p>
						);
					}}
					width={80}
				/>
				<Column
					title="Date"
					dataIndex="date"
					key="date"
					className="bg-white"
					width={120}
				/>
				<Column
					title="Customer"
					dataIndex="customer"
					key="customer"
					className="bg-white"
					width={150}
				/>
				<Column
					title="QBO Invoice"
					dataIndex="qboInvoice"
					key="qboInvoice"
					className="bg-white"
					width={130}
					render={(text, data: any) => {
						return (
							text && (
								<a
									className="text-color"
									href={`${
										process.env.REACT_APP_INVOICE_ENDPOINT
									}/app/invoice?txnId=${text?.replace(
										/\D/g,
										''
									)}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									{data.qboInvoiceDoc}
								</a>
							)
						);
					}}
				/>
				<Column
					title="EES Order"
					dataIndex="eesOrder"
					key="eesOrder"
					className="bg-white"
					width={120}
					render={(text, record: any) =>
						record.eesOrder === 'Total' ? (
							<p>{text}</p>
						) : record.isSubTotal ? (
							''
						) : (
							<a className="text-color">
								{invalidText(text) ? '-' : text}
							</a>
						)
					}
				/>
				<Column
					title="Product Details"
					dataIndex="product"
					key="product"
					className="bg-white"
					width={120}
					render={(text, data: any) => {
						if (text && !data.isTotal) {
							return <p>{text}</p>;
						}
						if (data.isTotal) {
							return '';
						} else {
							return '-';
						}
					}}
				/>
				<Column
					title="Amount"
					dataIndex="amount"
					key="amount"
					className="bg-white"
					width={120}
					render={(text, data: any) => {
						return (
							<p>
								{text != null && !data.isTotal
									? `$${text}`
									: data.isTotal
									? `$${text}`
									: '$0'}
							</p>
						);
					}}
				/>
				<Column
					title="Adj.Profit"
					dataIndex="adjProfit"
					key="adjProfit"
					className="bg-white"
					width={140}
					render={(text) =>
						text !== null ? <p>{text ? `$${text}` : text}</p> : null
					}
				/>
				<Column
					title="Commission %"
					dataIndex="commission"
					key="commission"
					className="bg-white"
					width={140}
					render={(text) =>
						text !== null ? <p>{text ? `${text}%` : text}</p> : null
					}
				/>
				<Column
					title="Commission Amount"
					dataIndex="commissionAmount"
					key="commissionAmount"
					className="bg-white"
					width={120}
					render={(text) => {
						return <p>{text ? `$${text}` : '$0'}</p>;
					}}
				/>
				<Column
					title="Deductions(if any)"
					dataIndex="deductions"
					key="deductions"
					className="bg-white"
					width={140}
					render={(text) =>
						text !== null ? <p>{text ? `$${text}` : '$0'}</p> : null
					}
				/>
				{/* <Column
					title="Fees"
					dataIndex="fees"
					key="fees"
					className="bg-white"
					width={120}
					render={(text) => {
						return <p>{text != null ? `$${text}` : '$0'}</p>;
					}}
				/> */}
				<Column
					title="Payouts Amount"
					dataIndex="payoutsAmount"
					key="payoutsAmount"
					className="bg-white"
					width={130}
					// render={(text) => <span>${text}</span>}
					render={(value, data: any) => {
						return data.isTotal ? (
							<p>${data.payoutsAmount}</p>
						) : (
							<p>${data.payoutsAmount}</p>
						);
					}}
				/>
				<Column
					title="Payout Date"
					dataIndex="payoutDate"
					key="payoutDate"
					className="bg-white"
					width={120}
				/>
			</Table>
		</div>
	);
};

export default NewProductReportTable;
